import { faCamera, faCircleLeft, faCircleRight, faEdit, faSave, faShareFromSquare, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
import { Modal, Form, Row, Col, Card, Image, Button } from "react-bootstrap";
import StickersService from "./services/stickers.service";
import Comment from "./Comment";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Share } from '@capacitor/share';

export default function StickerDetailModal({ show, onClose, data, setData }) {
  const [isOpenFullscreen, setIsOpenFullscreen] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const [showGlueNameChange, setShowGlueNameChange] = useState(false)
  const [newGlueNameValue, setNewGlueNameValue] = useState("")
  const [favButtonDisabled, setFavButtonDisabled] = useState(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)
  const [disablePrevButton, setDisabledPrevButton] = useState(true)
  const [disableNextButton, setDisabledNextButton] = useState(true)
  const [commentName, setCommentName] = useState("")
  const [commentText, setCommentText] = useState("")
  const [displayCommentForm, setDisplayCommentForm] = useState(true)

  useEffect(() => {
    if (show) {
      setCurrentPhotoIndex(0)
      setDisplayCommentForm(true)
      setFavButtonDisabled(false)
      if (data.photoIds.length > 1) {
        setDisabledPrevButton(true)
        setDisabledNextButton(false)
      } else {
        setDisabledPrevButton(true)
        setDisabledNextButton(true)
      }
    }
  }, [show])
  

  const openPicture = () => {
    if (data.photoIds.length > 0) {
      setIsOpenFullscreen(true)
      setOpacity(0)
    }
  }
  const onHideFullscreen = () => {
    setIsOpenFullscreen(false)
    setOpacity(1)
  }


  const prevPicture = () => {
    if (currentPhotoIndex === 0) {
      return
    }
    if (currentPhotoIndex === 1) {
      setDisabledPrevButton(true)
    }
    if (currentPhotoIndex === (data.photoIds.length - 1)) {
      setDisabledNextButton(false)
    }
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1)
    }
  }

  const nextPicture = () => {
    if (currentPhotoIndex === (data.photoIds.length - 1)) {
      return
    }
    if (currentPhotoIndex === 0) {
      setDisabledPrevButton(false)
    }
    if (currentPhotoIndex === (data.photoIds.length - 2)) {
      setDisabledNextButton(true)
    }
    setCurrentPhotoIndex(currentPhotoIndex + 1)
  }

  const increaseFoundCount = () => {
    if (favButtonDisabled) {
      return
    }
    StickersService.increaseFoundCount(data.stickerId).then((response) => {
      data.foundCount = response.data
      setFavButtonDisabled(true)
    })
  }

  const handleInputNewGlueName = () => {
    setNewGlueNameValue("")
    setShowGlueNameChange(true)
  }

  const handleSaveNewGlueName = () => {
    console.log("newGlueNameValue", newGlueNameValue)
    if (newGlueNameValue !== "") {
      StickersService.putGlueName(data.stickerId, newGlueNameValue).then((response) => {
        setData({
          ...data,
          glueName: newGlueNameValue
        })
        //data.glueName = newGlueNameValue
        setShowGlueNameChange(false)
      })
    }
  }

  const handleNewGlueNameValue = (event) => {
    setNewGlueNameValue(event.target.value)
  }

  const addComment = () => {
    console.log("addComment", commentName, commentText)
    StickersService.postComment(data.stickerId, { name: commentName, comment: commentText }).then((response) => {
      console.log("Add to list")
      setCommentName("")
      setCommentText("")
      var newComments = [...data.comments, response.data]
      setData({
        ...data,
        comments: newComments
      })
      //data.comments = newComments
      setDisplayCommentForm(false)

    })
  }

  const changeName = (elem) => {
    setCommentName(elem.target.value)
  }

  const changeText = (elem) => {
    setCommentText(elem.target.value)
  }

  const createNewPicture = async () => {
    console.log("createNewPicture")
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
    });

    if (!image) {
      return
    }
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    //var imageUrl = image.webPath;

    // Can be set to the src of an image now
    console.log(image.base64String)
    StickersService.postPhoto(data.stickerId, image.base64String).then((response) => {
      const photoId = response.data;
      const newPhotoIds = [...data.photoIds, photoId];
      //setPhotoIds(newPhotoIds);
      setData({
        ...data,
        photoIds: newPhotoIds
      })
      setDisabledNextButton(false);
    })
  }
  const createNewGalleryPicture = async () => {
    console.log("createNewPicture")
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos
    });

    if (!image) {
      return
    }
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    //var imageUrl = image.webPath;

    // Can be set to the src of an image now
    console.log(image.base64String)
    StickersService.postPhoto(data.stickerId, image.base64String).then((response) => {
      const photoId = response.data;
      const newPhotoIds = [...data.photoIds, photoId];
      //setPhotoIds(newPhotoIds);
      setData({
        ...data,
        photoIds: newPhotoIds
      })
      setDisabledNextButton(false);
    })
  }

  const shareLink = async () => {
    await Share.share({
      url: process.env.REACT_APP_API_URL + "/id/" + data.stickerId,
    });
  }

  return (
    <>
      <Modal show={isOpenFullscreen} onHide={onHideFullscreen} onClose={onHideFullscreen} fullscreen={true} scrollable={true}>
        <Modal.Header closeButton>Bildansicht</Modal.Header>
        <Modal.Body>
          <Image src={data.stickerId ? process.env.REACT_APP_API_URL + "/v1/sticker/" + data.stickerId + "/photo/" + data.photoIds[currentPhotoIndex] : ""} fluid style={{ cursor: "pointer", width: "100%" }} onClick={onHideFullscreen}></Image>
        </Modal.Body>
      </Modal>
      <Modal style={{ opacity: opacity }} show={show} onHide={onClose} size="xl" scrollable={true} onClose={onClose} data-bs-theme="light">
        <Modal.Header closeButton>Sticker
          <div style={{textAlign: "right", width: "100%"}}><FontAwesomeIcon icon={faShareFromSquare} style={{cursor: "pointer", paddingRight: "10px"}} className="divclass" onClick={shareLink} ></FontAwesomeIcon></div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row style={{ display: showGlueNameChange ? 'none' : '' }}>
              <Form.Label column>Kleber:</Form.Label>
              <Col>
                <Form.Label column>{data.glueName !== "" ? data.glueName : <>unbekannt&nbsp;<FontAwesomeIcon icon={faEdit} onClick={handleInputNewGlueName} style={{cursor: "pointer"}} /></>}</Form.Label>
              </Col>
            </Row>
            <Row style={{ display: showGlueNameChange ? '' : 'none' }}>
              <Form.Label column>Kleber:</Form.Label>
              <Col>
                  <Row>
                    <Col xs={"auto"}><Form.Control placeholder="Gib einen Kleber ein" onInput={handleNewGlueNameValue} /></Col>
                    <Col xs={"auto"}><Button onClick={handleSaveNewGlueName}>Speichern</Button></Col>
                  </Row>
              </Col>
            </Row>
            {data.findName !== "" ? <Row>
              <Form.Label column>Finder:</Form.Label>
              <Col>
                <Form.Label column>{data.findName}</Form.Label>
              </Col>
            </Row> : <></>}
          </Form>
          <hr />
          <div style={{ width: "100%", display: "flex", height: "50vh" }} className="d-flex align-items-center justify-content-center">
            <div style={{ width: "80px" }}>
              <FontAwesomeIcon icon={faCircleLeft} size="3x" className={disablePrevButton ? "fa-disabled" : ""} style={{ cursor: "pointer" }} onClick={prevPicture} />
            </div>
            <div style={{ flexGrow: 1, textAlign: "center" }}><Image
              fluid
              onClick={openPicture}
              src={typeof (data.photoIds) === "undefined" || data.photoIds.length === 0 ? "/no_picture.png" : process.env.REACT_APP_API_URL + "/v1/sticker/" + data.stickerId + "/photo/" + data.photoIds[currentPhotoIndex]}
              style={{ minHeight: "10vh", maxHeight: "50vh", cursor: "pointer" }}></Image></div>
            <div style={{ width: "80px" }} className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faCircleRight} size="3x" className={disableNextButton ? "fa-disabled" : ""} style={{ cursor: "pointer" }} onClick={nextPicture} />
            </div>
          </div>
          <div style={{ textAlign:"right" }}>
            <span style={{marginRight: "10px"}}><Button  onClick={createNewGalleryPicture}>
              <span className="fa-layers" style={{marginRight: "15px", marginTop: "5px", marginBottom: "5px"}}>
              <FontAwesomeIcon icon={faImage} size="2x" style={{}}></FontAwesomeIcon>
              <span class="dot" style={{backgroundColor:'#0d6efd', height: "20px", width: "20px", borderRadius: "50%", display: "inline-block", left: "-12px", position: "absolute", top: "10px"}}></span>
              <FontAwesomeIcon icon={faPlusCircle} style={{backgroundColor: "#0d6efd", left: "-20px", top: "20px"}}></FontAwesomeIcon>
              </span>
              </Button>
              </span>
            <span><Button  onClick={createNewPicture}>
            <span className="fa-layers" style={{marginRight: "15px", marginTop: "5px", marginBottom: "5px"}}>
            <FontAwesomeIcon icon={faCamera} size="2x"></FontAwesomeIcon>
            {/*<FontAwesomeIcon icon={faPlusCircle} style={{backgroundColor: "#0d6efd", left: "-20px", top: "20px"}}></FontAwesomeIcon>*/}
            <span class="dot" style={{backgroundColor:'#0d6efd', height: "20px", width: "20px", borderRadius: "50%", display: "inline-block", left: "-12px", position: "absolute", top: "10px"}}></span>
            <FontAwesomeIcon icon={faPlusCircle} style={{left: "-20px", top: "20px"}}></FontAwesomeIcon>
            </span>
            </Button></span>
          </div>
          <hr></hr>
          <Form>
            <Row>
              <Form.Label column>Favorites:</Form.Label>
              <Col>
                <Form.Label column>{data.foundCount}</Form.Label>&nbsp;<Image src="./ivo.gif" style={{ cursor: "pointer" }} className={favButtonDisabled ? "favGray" : ""} onClick={increaseFoundCount} />
              </Col>
            </Row>
          </Form>
          <hr></hr>
          <Card>
            <Card.Header>Kommentare</Card.Header>
            <Card.Body>
              {typeof (data.comments) === "undefined" || data.comments.length === 0 ? "Keine Kommentar vorhanden" : data.comments.map((comment) => <Comment key={comment.ID} comment={comment} />)}

            </Card.Body>
          </Card><br />
          <Card style={{ display: displayCommentForm ? "" : "none" }}>
            <Card.Header>Schreibe einen Kommentar</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control placeholder="Gib deinen Namen ein" onInput={changeName} value={commentName} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Kommentar
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control placeholder="Gib einen Kommentar ein" onInput={changeText} value={commentText} />
                  </Col>
                </Form.Group>
                <Button variant="primary" className="float-end" onClick={addComment}>
                <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>&nbsp;Abschicken
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}
