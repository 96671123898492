import axios from 'axios';

class StatisticsService {

    getTop10() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=');
    }
    getLastWeek() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=lastweek');
    }
    getLastMonth() {
        return axios.get(process.env.REACT_APP_API_URL + '/v1/statistics?type=lastmonth');
    }

}

export default new StatisticsService();