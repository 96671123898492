import { React, useState, useEffect, useRef } from 'react';
import {useParams} from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet'
import { Button, Modal } from "react-bootstrap";

import './App.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'
import StickerDetailModal from "./StickerDetailModal.js";
import NewStickerModal from "./NewStickerModal.js";
import StickersService from "./services/stickers.service";

import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";

import 'leaflet-geosearch/dist/geosearch.css';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Map() {
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState([52.3759, 9.7320]);
  const [markers, setMarkers] = useState([])
  const [show, setShow] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [showNewStickerModal, setShowNewStickerModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [locationPosition, setLocationPosition] = useState([0, 0])
  const positionRef = useRef(null)
  const [spinnerText, setSpinnerText] = useState("Sticker werden geladen")
  const [flyToLocation, setFlyToLocation] = useState(null)
  const [flyToAlreadyOpened, setFlyToAlreadyOpened] = useState(false)

  let { id } = useParams();

  const loadStickers = () => {
    setSpinnerText("Sticker werden geladen")
    setSpinner(true)
    StickersService.getStickers().then(
      response => {
        const markers = [];
        response.data.forEach((element: any) => {
          var marker = {
            id: element.ID,
            position: [element.latitude, element.longitude],
            data: {
              name: element.name,
              personType: element.person_type,
              glueName: element.glue_name,
              findName: element.find_name,
              foundCount: element.found_count,
              photoIds: element.photo_ids,
              comments: element.comments,
              stickerId: element.ID,
            }
          };
          markers.push(marker);
  
          if (typeof id !== 'undefined' && element.ID == id) {
            console.log("FOUND sticker id")
            setFlyToLocation([element.latitude, element.longitude])
          }
          setMarkers(markers)
          setSpinner(false)
          })
    },
      error => {
        console.log("StickersService Error getPublicContent", error.response);
        setSpinner(false)
      }
    );
  }


  useEffect(() => {
    if (flyToLocation !== null && !flyToAlreadyOpened) {
      if (map) {
        map.flyTo(flyToLocation, 17);
        setModalData(markers.find(x => x.id == id).data)
        handleOpen();
        setFlyToAlreadyOpened(true)
      }
    }
    
  }, [flyToLocation]);


  useEffect(() => {
    loadStickers();
  }, []);

  // If we close the new sitcker modal, we close the opened popup and reload the stickers
  useEffect(() => {
    console.log(showNewStickerModal);
    if (showNewStickerModal || !positionRef.current) {
      return
    }
    positionRef.current.closePopup()
    setLocationPosition([0, 0])
    loadStickers()
    
  }, [showNewStickerModal])

  const iconIvo = new L.Icon({
    iconUrl: require('./ivo.gif'),
    iconRetinaUrl: require('./ivo.gif'),
    iconSize: new L.Point(32, 20),
  });


  useEffect(() => {
    if (!map) return;

    const easyButton = L.easyButton("fa-map-marker", () => {
      setSpinnerText("Position wird gesucht")
      setSpinner(true)
  
      map.locate().on("locationfound", function (e) {
        setSpinner(false)
        setPosition(e.latlng);
        setLocationPosition(e.latlng)
        positionRef.current.closePopup()
        map.flyTo(e.latlng, map.getZoom());
      });
      map.on("locationerror", function (e) {
        setSpinner(false)
        alert("Error on getting current position: " + e.message);
      })
    })
    easyButton.addTo(map);

    let _dblClickTimer = null;
    map.on("click", function (e) {
      if (_dblClickTimer !== null) {
        return
      }
      _dblClickTimer = setTimeout(() => {
        // On the first click for a marker the map always pan with the marker in the upper right corner.
        // The next clicks this didn't happenend. This workaround remembers the center of the map and
        // recenter the map after some milliseconds to its position.
        const center = map.getCenter()
        setLocationPosition(e.latlng)
        positionRef.current.openPopup()
        setTimeout(() => {
          map.panTo(center)
        }, 10);
        _dblClickTimer = null;
      }, 200)
    });
    map.on("dblclick", function() {
      clearTimeout(_dblClickTimer);
      _dblClickTimer = null;
    })

    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider: provider,
      showMarker: false,
      showPopup: false,
      autoClose: true,
      style: window.innerWidth > 400 ? "bar" : "button",
      searchLabel: "Gebe eine Adresse ein",
    });
    map.addControl(searchControl);

  }, [map]);

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const handleOpenNewStickerModal = () => setShowNewStickerModal(true);
  const handleCloseNewStickerModal = () => setShowNewStickerModal(false);

  const addNewSticker = (data) => {
    console.log("addNewSticker", data)
  }

  return (
    <>    
      <Modal show={spinner} backdrop="static" keyboard={false} centered={true} size="sm">
        <Modal.Body>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-xl"></FontAwesomeIcon>&nbsp;{spinnerText}
        </Modal.Body>
      </Modal>
      <MapContainer center={position} zoom={13} scrollWheelZoom={true} ref={setMap} >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MarkerClusterGroup>
          {markers.map(
            (p) => (
              <Marker key={p.id} position={p.position} radius={5} icon={iconIvo} data={p.data} eventHandlers={{
                click: (e) => {
                  setModalData(e.target.options.data)
                  handleOpen();
                },
              }}></Marker>
            )
          )}
        </MarkerClusterGroup>
        <CircleMarker center={locationPosition} radius={5} stroke="#fff" ref={positionRef}>
          <Popup><Button onClick={handleOpenNewStickerModal}>Neuen Sticker anlegen</Button></Popup>
        </CircleMarker>

      </MapContainer>
      <StickerDetailModal show={show} onClose={handleClose} data={modalData} setData={setModalData} />
      <NewStickerModal show={showNewStickerModal} onClose={handleCloseNewStickerModal} data={locationPosition} addNewSticker={addNewSticker} />
    </>
  );
}

export default Map;